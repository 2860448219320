import React from "react";
import s from "../assets/scss/about.module.scss";

import { Link } from "react-router-dom";

import veritas from "../assets/img/veritas-logo.png";
import shape from "../assets/img/shapes/shape-about.svg";
import shapeSquare from "../assets/img/shapes/shape-about-square.svg";

import AnimatedButton from "../components/AnimatedButton";

import referenceSymantec from "../assets/img/referenceLogos/symantec.svg";
import referenceVeritas from "../assets/img/referenceLogos/veritas.svg";
import referenceDell from "../assets/img/referenceLogos/dell-technologies.svg";
import referenceIbm from "../assets/img/referenceLogos/ibm.svg";
import referenceArctera from "../assets/img/referenceLogos/arctera_logo.png";
import referenceCohesity from "../assets/img/referenceLogos/cohesity_logo.png"; 

import referenceVmware from "../assets/img/referenceLogos/vmware.svg";
import referenceCisco from "../assets/img/referenceLogos/cisco.svg";
import referenceLenovo from "../assets/img/referenceLogos/lenovo.svg";
import referenceFileOrbis from "../assets/img/referenceLogos/file-orbis.svg";

import referenceIvanti from "../assets/img/referenceLogos/ivanti.svg";
import referenceOctoxlabs from "../assets/img/referenceLogos/octoxlabs.svg";
import referenceGtb from "../assets/img/referenceLogos/gtb-technologies.png";
import referenceMirket from "../assets/img/referenceLogos/mirket-logo.png";
import referenceCrowdstrike from "../assets/img/referenceLogos/crowdstrike.svg";
import referenceProofpoint from "../assets/img/referenceLogos/proofpoint.svg";
import referenceAws from "../assets/img/referenceLogos/aws.svg";
import referenceManageEngine from "../assets/img/referenceLogos/manageengine.svg";  
import referenceOpswat from "../assets/img/referenceLogos/opswat_logo.png";
import referencePaloalto from "../assets/img/referenceLogos/paloalto-logo.png";
import referenceS3m from "../assets/img/referenceLogos/s3m-logo.png";
import InfiniteMarquee from "../components/InfiniteMarquee";
import ReferencesMobile from "../components/ReferencesMobile";
import ContactUs from "../components/ContactUs";
import useWindowSize from "../hooks/useWindowSize";
import { breakpointTypes } from "../utils/types";
import RevealWrapper from "../components/RevealWrapper";
import aboutTop from "../assets/img/about-top.jpg";
import aboutBottom from "../assets/img/about-bottom.jpg";

export default function About() {
  const windowSize = useWindowSize();

  return (
    <main className={s.about}>
                  <style>
        {`
          @media screen and (max-width: 550px) {
            .referencesMobile_referencesMobile__eoUWc .referencesMobile_row__z3tcv .referencesMobile_logoWrapper__MxxD0 .referencesMobile_companyLogo__kNMpd {
              max-height: 50%;
              max-width: 150px;
            }
          }
        `}
      </style>
      <section className={s.hero}>
        <div className={s.sphereWrapper}>
          <img
            src={shape}
            alt="Abastract Shape"
            className={s.img}
            data-parallax
            data-speed="0.5"
          />
        </div>
        <small className={s.breadcrumb}>Hakkımızda</small>
        <h1 className={s.title}>
          <span>Yeni Nesil</span> <br /> Bilişim Danışmanı...
        </h1>
        <div className={s.bottom}>
          <div className={s.imgWrapper} data-scaling-img-wrapper>
            <img
              src={aboutTop}
              alt="Company Related Visual"
              className={s.img}
              data-scaling-img
            />
          </div>
          <div className={s.right}>
            <p className={s.greeting}>
              Bilgi teknolojileri alanında işletmelerin iç dinamiklerine özel en
              faydalı çözümleri sunma vizyonu ile 2019 yılında faaliyetlerimize
              başladık.
            </p>
            <img src={veritas} alt="Veritas Logo" className={s.logo} />
          </div>
        </div>
      </section>
      <section className={s.mContainer}>
        {/* {windowSize.width <= breakpointTypes.tablet ? (
          <>
            <h2 className={s.marqueeText} data-marquee-slide-inner>
              <span className={s.part}>
                <span className={s.large}>+1048</span> Kapanmış Çağrı
                <span className={s.comma}>,</span>
              </span>
              <span className={s.part}>
                <span className={s.large}>
                  <span className={s.percentage}>%</span>98
                </span>
                Servis Yenileme Oranı<span className={s.comma}>,</span>
              </span>
              <span className={s.part}>
                <span className={s.large}>+15</span>Yıl Deneyim
                <span className={s.comma}>,</span>
              </span>
            </h2>
          </>
        ) : (
          <InfiniteMarquee speed={20}>
            <h2 className={s.marqueeText} data-marquee-slide-inner>
              <span className={s.part}>
                <span className={s.large}>
                  <span className={s.percentage}>%</span>98
                </span>
                Servis Yenileme Oranı,
              </span>
              <span className={s.part}>
                <span className={s.large}>+1048</span> Kapanmış Çağrı,
              </span>
              <span className={s.part}>
                <span className={s.large}>+15</span>Yıl Deneyim,
              </span>
            </h2>
          </InfiniteMarquee>
        )} */}

        <InfiniteMarquee speed={20}>
          <h2 className={s.marqueeText} data-marquee-slide-inner>
            <span className={s.part}>
              <span className={s.large}>
                <span className={s.percentage}>%</span>98
              </span>
              Servis Yenileme Oranı,
            </span>
            <span className={s.part}>
              <span className={s.large}>+1823</span> Kapanmış Çağrı,
            </span>
            <span className={s.part}>
              <span className={s.large}>+15</span>Yıl Deneyim,
            </span>
          </h2>
        </InfiniteMarquee>
      </section>
      <section className={s.content}>
        <div className={s.top}>
          <div className={s.left}>  
            <div className={s.textWrapper}>
              <h3 className={s.text}>
                Alanında uzman ve deneyimli ekiplerimizle işletmelerin dijital
                dönüşümlerine <span>rehberlik ediyoruz.</span>
              </h3>
              <div className={s.btnWrapper}>
                {" "}
                <Link to="/iletisim">
                  <AnimatedButton text="Bize Ulaşın"></AnimatedButton>
                </Link>
              </div>
            </div>

            <div className={s.imgWrapper} data-scaling-img-wrapper>
              <img
                data-scaling-img
                src={aboutBottom}
                alt="Company Related Visual"
                className={s.img}
              />
            </div>
          </div>
          <div className={s.right}>
            <p className={s.par}>
              <strong>15 yılı aşkın tecrübemizi </strong>
              yeni nesil çalışma şartlarıyla harmanlayarak başta{" "}
              <strong>
                siber güvenlik, kurumsal dosya yönetimi, mobil cihaz yönetimi,
                sanallaştırma, bilgisayar ağları
              </strong>{" "}
              olmak üzere çeşitli alanlarda uzmanlaşmış danışmanlık hizmetleri
              ve çözümler sunuyoruz.
            </p>
            <p className={s.par}>
              Günümüz işletmelerinin hızla gelişen ve değişen bilişim
              teknolojilerinden <strong>en iyi şekilde</strong>{" "}
              faydalanabilmeleri için{" "}
              <strong>ihtiyaçlarına en uygun çözümleri</strong> bir araya
              getirerek başarıya ulaşmalarına{" "}
              <strong>yardımcı oluyoruz.</strong>
            </p>
          </div>
        </div>

        <div className={s.bottom}>
          <div className={s.textWrapper}>
            <div className={s.squareWrapper}>
              <img src={shapeSquare} alt="Abastract Shape" className={s.img} />
            </div>
            <h3 className={s.text}>
              Ateon olarak her sektöre hizmet verebilen profesyonel servis ekimizle Türkiye’nin önde gelen kuruluşlarına 
              60’ı aşkın <span>destek anlaşması</span> ve bu anlaşmalar kapsamında 25.000’i aşkın kullanıcıya <span>özverili ve güvenilir</span> 
              hizmetler sunmaktayız.
            </h3>
          </div>
          <div className={s.references}>
            {windowSize.width > breakpointTypes.mobile ? (
              <div className={s.referenceLogos}>
                <div className={s.row}>
                  <a
                    href="https://www.arctera.io/"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceArctera}
                      alt="Company Logo"
                    ></img>
                  </a>
                  <a
                    href="https://www.cohesity.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceCohesity}
                      alt="Company Logo"
                    />
                  </a>
                  <a
                    href="https://www.dell.com"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceDell}
                      alt="Company Logo"
                    />
                  </a>
                  <a
                    href="https://www.ibm.com"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceIbm}
                      alt="Company Logo"
                    />
                  </a>
                </div>

                <div className={s.row}>
                  <a
                    href="https://www.vmware.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceVmware}
                      alt="Company Logo"
                    />
                  </a>
                  <a
                    href="https://www.cisco.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceCisco}
                      alt="Company Logo"
                    />
                  </a>
                  <a
                    href="https://www.lenovo.com"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceLenovo}
                      alt="Company Logo"
                    />
                  </a>
                  <a
                    href="https://www.fileorbis.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceFileOrbis}
                      alt="Company Logo"
                    />
                  </a>
                </div>

                <div className={s.row}>
                <a
              href="https://mirketsecurity.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceMirket}
                alt="Company Logo"
                style={{ maxHeight: "100%" }}
              />
            </a>
                  <a
                    href="https://www.octoxlabs.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceOctoxlabs}
                      alt="Company Logo"
                    />
                  </a>
                  <a
                    href="https://gttb.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={s.logoWrapper}
                  >
                    <img
                      data-company-logo
                      className={s.companyLogo}
                      src={referenceGtb}
                      alt="Company Logo"
                    />
                  </a>
                  <a
              href="https://www.paloaltonetworks.com/cortex"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referencePaloalto}
                alt="Company Logo"
              />
            </a>
            
                </div>
                <div className={s.row}>
                <a
              href="https://www.opswat.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceOpswat}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://s3msecurity.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceS3m}
                alt="Company Logo"
                style={{ maxHeight: 200 }}
              />
            </a>
            <a
              href="https://aws.amazon.com/tr/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceAws}
                alt="Company Logo"
              />
            </a>
            <a
              href="https://www.manageengine.com/"
              target="_blank"
              rel="noreferrer"
              className={s.logoWrapper}
              data-logo-wr
            >
              <img
                data-company-logo
                className={s.companyLogo}
                src={referenceManageEngine}
                alt="Company Logo"
              />
            </a>
                  </div>
              </div>
            ) : (
              <ReferencesMobile />
            )}

            <div className={s.par}>
            <strong>Bilgi Teknolojileri</strong> alanında sektör lideri global ve yerli firmaların çözümlerini 
            uzun yıllara dayanan deneyimlerimizle <strong>katma değer</strong> sağlayarak müşterilerimize sunuyoruz.
            </div>
          </div>
        </div>
      </section>
      <RevealWrapper>
        <ContactUs
          text={
            <>
              Yalnızca kurulum değil satış sonrası verdiğimiz bakım ve destek
              hizmetleriyle dijital dönüşüm yolculukları boyunca her zaman
              müşterilerimizin yanında yer alıyoruz.
            </>
          }
        ></ContactUs>
      </RevealWrapper>
    </main>
  );
}
